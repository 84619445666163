import React from 'react'
import { StaticQuery, graphql } from "gatsby"

import Cart from '../components/Cart/cart'

export default (props) => (
    <StaticQuery
        query={graphql`
            query cartProductQuery {
                allShopifyProduct {
                    edges {
                        node {
                            id
                            legacyResourceId
                            shopifyId
                            title
                            descriptionHtml
                            productType
                            tags
                            # images {
                            #     originalSrc
                            #     localFile {
                            #         childImageSharp {
                            #             fluid {
                            #                 ...GatsbyImageSharpFluid_withWebp_noBase64
                            #             }
                            #         }
                            #     }
                            # }
                            featuredImage {
                                originalSrc
                                localFile {
                                    childImageSharp {
                                        fluid {
                                            ...GatsbyImageSharpFluid_withWebp_noBase64
                                        }
                                    }
                                }
                            }
                            variants {
                                id
                                shopifyId
                                legacyResourceId
                                sku
                                price
                                presentmentPrices {
                                    price {
                                        amount
                                        currencyCode
                                    }
                                }
                                compareAtPrice
                                availableForSale
                                title
                                weight
                                weightUnit
                                image {
                                    localFile {
                                        childImageSharp {
                                            fluid {
                                                ...GatsbyImageSharpFluid_withWebp_noBase64
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `}
    >
        {data => <Cart data={data} {...props} />}
    </StaticQuery>
);