import qs from 'qs';
import { navigate } from "gatsby";

const navigateToCheckout = (cart, query) => {
    let url = cart.webUrl.replace('tearribles.myshopify', 'secure.tearribles');

    if (query) {
        const queryString = qs.stringify(query);
        url = url.includes('?')
            ? `${url}&${queryString}`
            : `${url}?${queryString}`;
    }

    if (window && window.location.assign) {
        // navigate('/cart', { replace: true })
        window.location.assign(url);
    } else if (window) {
        window.location.href = url;
    }
}

export default navigateToCheckout;